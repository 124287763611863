.view {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  /* width: 100%;
    height: 100%; */
  /* margin: auto 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.view > img {
  width: 350px;
  padding-bottom: 30px;
}

.content {
  border-radius: 30px;
  box-shadow: 1px;
  width: 50%;
  height: 377px;
  /* height: 60vh; */
  display: flex;
  background-color: #fefefe;
  overflow: hidden;
  box-shadow: 10px 10px 40px #2b2b2b;
}

.content-form {
  position: relative;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  padding: 20px;
  width: 40%;
}

.content-form-login {
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-img {
  border-right: 10px solid rgba(156, 156, 156, 0.5);
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.content-img > img {
  display:inline-block;
  width:100%;
  height:100%;
}

.tag {
  border-radius: 0 25px 25px 0;
  color: #fefefe;
  width: 63%;
  margin-left: calc(-30px);
  background: rgb(0, 110, 189);
  background: linear-gradient(240deg, rgba(0, 110, 189, 1) 0%, rgba(7, 61, 97, 1) 100%);
}

h3 {
  text-align: center;
  padding: 10px 20px;
  margin: 0;
}

h2 {
  padding: 10px;
  margin: 0;
  text-align: center;
}

.field {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 5px;
}

.field-input {
  width: 90%;
}

.field:last-child {
  bottom: 0;
  /* position: absolute; */
}

.rodape {
  display: flex;
  /* bottom: 0; */
  /* position: fixed; */
  margin:15px 0 20px 0;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fefefe;
}

.logo-rodape {
  margin-top: 5px;
  margin-left: 8px;
  width: 80px;
}

a {
  text-decoration: none;
  color: #fefefe;
}

.text-copy {
  font-size: 14px;
}

.content-copy {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 300px; */
}

.erro-text {
  color: #fefefe;
}

@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .content {
    width: 80%;
  }

  .view > img {
    width: 300px;
    padding-bottom: 30px;
  }

  footer {
    text-align: center;
    padding: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .content {
    width: 80%;
  }

  .view > img {
    width: 300px;
    padding-bottom: 30px;
  }

  .content-img {
    display: none;
  }

  .content-form {
    width: 100%;
  }

  footer {
    text-align: center;
    padding: 20px;
  }
}