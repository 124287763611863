@font-face {
  font-family:customPoppinsRegular;
  src:url("https://bibliotecas.cosmospro.com.br/cosmos/e-pedidos-web/nazaria/v2/fonts/Poppins-Regular.ttf");
}

html, body {
  font-family: customPoppinsRegular, Roboto, Helvetica, Arial, sans-serif;
  display:flex;
  width:100%;
  min-height:100%;
  padding: 0;
  margin: 0;
}

body {
  background: rgb(0, 110, 189);
  background: linear-gradient(90deg, rgba(0, 110, 189, 1) 0%, rgb(3, 52, 85) 100%);
}

#root, html > body > #root {
  height: 100%;
  width:100% !important;
}

.shadowHost {
  display: flex;
  height: 100%;
}